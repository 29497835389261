.introduce{
    display: flex;
    align-items: center;
    margin-bottom: 120px;
    @media (max-width: 650px) {
        margin-bottom: 10px;
    }
    .responsive-iframe {
        width: 520px;
        height: 292.5px;
        @media (max-width: 650px) {
            width: 100%;
            height: auto;
        }
    
    }
    .text-introduce {
        flex: 1;
        .heading {
            color: #000;
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 38px;
        }
        .content-introduce {
            color: #000;
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 38px;
            .nb-tot{
                color: #000;
            }
            .m2m{
                color: #000;
            }
        }
        .content-introduce1 {
            color: #000;
            font-size: 16px;
            font-weight: 300;
        }
    }
    .image-introduce {
        margin-left: 57px;
        // margin-bottom: 100px;
    }
}
@media (max-width: 1200px) {
        .image-introduce{
           img{
            width: 100%;
           }
        }
}

@media (max-width: 992px) {
   .introduce{
    flex-direction: column;
    .image-introduce{
        margin-left: 0px;
        margin-bottom: 60px;
     }
   }
}

@media (max-width: 650px) {
    .image-introduce{
        width: 100%;
        img{
         width: 100%;
         height: auto;
        }
     }
}


  
  