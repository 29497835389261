

.info-call-list {
  display: flex;
  margin-top: 22px;
  font-size: 16px;
  line-height: 160%;
  color: #565656;
}

.call-head {
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 0.05em;
  color: #000000;
}

.info-call {
  margin-right: 32px;
}

.step-cont {
  .step-item {
    margin-bottom: 85px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.list-contact {
  position: fixed;
  right: 140px;
  display: flex;
  flex-direction: column;
  bottom: 20px;
}

.contact-item {
  margin-bottom: 20px;

  img {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 768px) {
}
