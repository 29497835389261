.sim-grid-view-page {
  padding: 56px 75px 75px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 60px 15px;
  .product-item {
    padding: 16px 13px 20px;
    background-color: #ffffff;
    .product-image {
      position: relative;
      width: 100%;
      padding-bottom: 66.67%;
      // height: auto;
      margin-bottom: 10px;
      img {
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    .product-saved {
      background: linear-gradient(90deg, #1ed54c -19.53%, #0050f1 116.41%);
      border-radius: 5px;
      padding: 4px 10px 3px 10px;
      display: inline-block;
      .saved-text {
        font-weight: 500;
        font-size: 10px;
        color: #faff00;
        text-transform: uppercase;
        text-align: center;
      }
      .saved-amount {
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
      }
    }

    .product-title {
      font-size: 18px;
      font-weight: 700;
      color: #222222;
      margin-top: 12px;
      margin-bottom: 12px;
i {
  font-style: normal;
  color: #f60;
}

    }

    .product-description {
      margin: 5px 0 12px;
      .content-one {
        font-weight: 400;
        font-size: 10px;
        color: #222222;
      }
      .content-two {
        font-weight: 700;
        font-size: 14px;
        color: #006b8c;
      }
    }
    .product-price {
      color: #ff0000;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-top: 12px;
    }
    .product-discount {
      color: #222222;
      line-height: 12px;
      font-weight: 600;
      font-size: 14px;
      text-decoration: line-through;
    }
    .custom-class{
      height: 34px;
    }
    .product-text-package {
      font-weight: 500;
      font-size:12px;
      line-height: 15px;     
      color: #222222;
    }
    .product-package {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #006B8C;
    }
    // .product-discount::after {
    //   content: '';
    //   width: 100%;
    //   height: 1px;
    //   background: black;
    //   position: absolute;
    //   bottom: 6.5px;
    //   left: 0;
    // }
  }
  .product-item:hover {
    background: #e9e9e9;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 25px 15px;
  }
  @media (max-width: 990px) {
    gap: 12px;
  }

  @media (max-width: 650px) {
    padding: 20px;
    grid-template-columns: repeat(auto-fit, minmax(98px, 1fr)) !important;
    .product-item {
      padding: 12px;
      .product-saved {
        padding: 3px 6px 2px 6px;
        .saved-text {
          font-size: 5px;
        }
        .saved-amount {
          font-size: 6px;
        }
      }
      .product-description {
        margin: 5px 0 10px;
        .content-one {
          font-size: 8px;
        }
        .content-two {
          font-size: 12px;
        }
      }
      .product-text-package{
        font-size: 6px;
        line-height: 8px;
        margin-top: 0px !important;
      }
      .product-title {
        font-size: 10px;
        line-height: 13px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .product-price {
        font-size: 10px;
        line-height: 13px;
        margin-top: 0px !important;
      }
      .product-discount {
        font-size: 8px;
        line-height: 10px;
      }
      .product-package{
        font-size: 8px;
        line-height: 10px;
      }
      .custom-class{
        height: 23px !important;
      }
    }
  }
}
