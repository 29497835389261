.banner {
  position: relative;
  min-height: 647px;
  padding-top: 150px;
  padding-left: 8%;
}

.img-banner {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h1 {
  position: relative;
  z-index: 10;
  margin-bottom: 32px;
  color: #fff;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px; /* 143.478% */
}

.sub-slogan {
  position: relative;
  z-index: 10;
  margin-bottom: 64px;
  max-width: 390px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.btn-order-now {
  position: relative;
  z-index: 10;
  border-radius: 60px;
  background: #fff;
  padding: 20px 40px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.3px;

  a {
    color: black;
  }
}

@media (max-width: 1400px) {
  .banner {
    padding-top: 80px;
    min-height: 570px;
  }

  h1 {
    width: unset;
  }
}

@media (max-width: 1200px) {
  .banner {
    padding-top: 40px;
    min-height: 480px;
  }

  h1 {
    font-size: 32px;
    padding-top: unset;
    line-height: 44px;
  }
}

@media (max-width: 990px) {
  .banner {
    padding-top: 40px;
    min-height: 360px;
  }

  h1 {
    font-size: 32px;
    padding-top: unset;
    line-height: 44px;
    margin-bottom: 20px;
  }

  .sub-slogan {
    margin-bottom: 20px;
    width: unset;
  }

  .btn-order-now {
    font-size: 16px;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .banner {
    padding-top: 20px;
    min-height: 270px;
  }
  .img-mb-banner {
    position: absolute;
    width: 100%;
    //height: 100%;
    top: 0;
    left: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }
  }

  h1 {
    line-height: unset;
    font-size: 24px;
  }
  .sub-slogan {
    font-size: 14px;
    line-height: unset;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 32px;
  }
}
