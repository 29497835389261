.about-esim {
  h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    background: linear-gradient(97.35deg, #0050F1 -18.74%, #1ED54C 94.22%);
    background-clip: text;
    color: transparent;
    width: fit-content;
  }
  .esim-benefit {
    background-color: #fff;
  }

  .esim-benefit-sp {
    display: none;
  }

  .esim-benefit-content {
    display: flex;
    position: relative;
  }

  .esim-benefit-content img {
    position: absolute;
    bottom: 0;
  }

  .esim-benefit-right {
    padding-top: 60px;
    padding-left: 440px;
  }

  .esim-benefit-list {
    margin-top: 40px;
    margin-bottom: 40px;
    list-style: none;
    padding-left: 0;
  }

  .esim-benefit-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .esim-benefit-list li {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

  }

  .esim-benefit-list li:before {
    content: '';
    width: 44px;
    height: 44px;
    background: linear-gradient(97.35deg, #0050F1 -18.74%, #1ED54C 94.22%);
    border-radius: 50%;
    margin-right: 28px;
    display: inline-block;
    flex-shrink: 0;
  }

  .how-esim-work {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .esim-work-content{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .esim-work-step {
    display: flex;
    margin-bottom: 0;
  }

  .esim-work-step-item {
    //width: calc(100% / 3);
    text-align: center;
  }

  .esim-work-step-item img{
    margin-bottom: 44px;
  }

  .esim-step-title {
    background: linear-gradient(96.97deg, #006E99 7.82%, #006E99 103.98%);
    background-clip: text;
    color: transparent;
    width: fit-content;
    margin: auto auto 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    max-width: 330px;

  }

  .esim-step-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-width: 330px;
    margin: 0 auto;
  }

  .device-esim-support {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff;
  }

  .device-support-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 48px;

  }

  .device-esim-support-content {
    display: flex;
    justify-content: space-between;
  }

  .device-esim-support-left {
    max-width: 750px
  }

  .device-support-tabs {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: transparent;
      background: linear-gradient(97.35deg, #0050F1 -18.74%, #1ED54C 94.22%);
      background-clip: text;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;

    }

    .ant-tabs-tab-btn {
      color: #676767;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    .ant-tabs-ink-bar {
      background: linear-gradient(97.35deg, #0050F1 -18.74%, #1ED54C 94.22%);
    }

  }

  .device-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4px;
    margin-bottom: 48px;
  }

  .device-list-item {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 0;

  }

  .list-item-note {
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
    color: red

  }

  @media (max-width: 1024px) {
    .esim-benefit {
      display: none;
    }


    .esim-benefit-sp{
      display: block;
      cursor: pointer;

      .esim-benefit-item {
        background: #fff;
        border-top-right-radius: 100px;
        padding: 16px;
      }

      .circle {
        border-radius: 9999px;
        width: 50px;
        height: 50px;
        background: linear-gradient(97.35deg, #0050F1 -18.74%, #1ED54C 94.22%);
        margin-bottom: 16px;
      }

      .esim-benefit-content {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        height: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
      }
    }

    .device-list {
      margin-bottom: 20px;
    }

    .device-esim-support-content {
      flex-direction: column;
      gap: 20px;
    }

    .device-esim-support-right {
      text-align: center;
    }

    .device-support-desc{
      margin-bottom: 20px;
    }


    // Custom style antd
    .ant-tabs-top >.ant-tabs-nav::before {
      border-bottom: none;
    }

    .device-support-tabs .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs .ant-tabs-tab {
      padding: 4px 16px;
      border: 1px solid #676767;
      border-radius: 32px;
    }

    .device-support-tabs .ant-tabs-tab-btn {
      font-size: 16px;
      font-weight: 600
    }

    .device-support-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 16px;
      color: #fff;
    }

    .ant-tabs .ant-tabs-tab-active {
      background: linear-gradient(97.35deg, #0050F1 -18.74%, #1ED54C 94.22%);
      border: none;
    }
  }


  @media (max-width: 576px) {
    .device-support-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 12px;
      line-height: 20px;
    }
    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
      margin-left: 4px;
    }
    .device-support-tabs .ant-tabs-tab-btn {
      font-size: 12px;
      line-height: 20px;
    }

    .ant-tabs .ant-tabs-tab {
      padding: 4px 12px;
    }

    .device-list {
      grid-template-columns: repeat(1, 1fr);
    }

    .list-item-note {
      font-size: 12px;
      line-height: 20px;
    }

    .device-esim-support {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    h4 {
      font-size: 18px;
    }
    .device-support-desc {
      font-size: 14px;
      line-height: 20px;
    }

    .esim-work-content {
      align-items: flex-start;
    }

    .esim-work-content p {
      margin-bottom: 0;
      font-size: 16px;
    }

    .how-esim-work {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .esim-step-title{
      font-size: 18px;
      line-height: 20px;
    }

    .esim-step-desc {
      font-size: 16px;
      line-height: 20px;
    }

    .esim-benefit-sp .circle {
      width: 32px;
      height: 32px
    }

    .esim-work-step {
      margin-bottom: 0;
    }

    .device-list-item {
      font-size: 16px;
    }

    .device-support-desc {
      font-size: 16px;
    }
  }

}
