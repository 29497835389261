.slick-carousel {
  .slick-slide img {
    aspect-ratio: 1/1;
    width: 100%;
    height: auto;
  }

  width: 100%;
  .carousel-item {
    display: block;
    .image-section {
      padding: 25px;
      border: 3px solid #006e99;
      border-radius: 15px;
      margin-left: 19px;
      margin-right: 19px;
      .image-block {
        position: relative;
        padding-bottom: 100%;
        .image-item {
          display: inline-block;
          overflow: hidden;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          object-fit: contain;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .content-section {
      margin-left: 19px;
      margin-right: 19px;
      .carousel-title {
        margin-top: 20px;
        font-size: 26px;
        font-weight: 700;
      }
      .carousel-content {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
      .carousel-price {
        margin-top: 10px;
        color: #ff0000;
        font-size: 16px;
        font-weight: 700;
      }
      .carousel-discount-price {
        font-size: 12px;
        font-weight: 600;
        position: relative;
        line-height: 15px;
        color: #222222;
      }
      .carousel-discount-price::after {
        content: '';
        width: 100%;
        height: 1px;
        background: black;
        position: absolute;
        bottom: 8px;
        left: 0;
      }
      .contact-button {
        margin-top: 20px;
        background: linear-gradient(92.08deg, #1abf67 -4.69%, #0563da 129.37%);
        border-radius: 5px;
        line-height: 27px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        padding-right: 24px;
        padding-left: 24px;
        display: block;
        text-transform: uppercase;
        border: none;
        text-decoration: none !important;
      }
    }
  }
  // .slick-slide {
  //   margin: 0 10px;
  // }
  // .slick-list {
  //   margin: 0 -10px;
  // }
  // .slick-slide {
  //   transform: translate(100%, 0);
  // }


  .carousel-anti-theft-item {
    padding: 24px;
    background: #fff;
    border-radius: 12px;

    .carousel-title {
      margin-top: 12px;
      font-size: 20px;
      line-height: 28px;
      color: black
    }

    .carousel-brand {
      display: flex;
      gap: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #626D7C;
      margin-top: 12px;
      height: 20px;

    }

    .carousel-made-in{
      display: flex;
      gap: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #626D7C;
      margin-top: 8px;
      height: 20px;
    }

    .carousel-brand-name {
      color: #3259E8;
    }

    .carousel-made-in-name {
      color: #000000
    }

    .product-price {
      margin-top: 12px;
      color: #C32518;
      font-size: 24px;
      line-height: 32px;
    }

    .btn-order {
      margin-top: 12px;
      background: #006B8C;
      color: #fff;
      height: 32px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
