.collapsePanel {
  margin-top: 24px;
  .toggle-block {
    background: rgba(0, 133, 255, 0.12);
    border-radius: 12px;
    padding: 0 29px;
    display: flex;
    gap: 29px;
    cursor: pointer;
    .arrow-section {
      width: 22px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .title-section {
      font-weight: 600;
      font-size: 18px;
      color: #000000;
      line-height: 64px;
    }
  }
  .main-block {
    background: rgba(0, 133, 255, 0.04);
    border-radius: 12px;
    margin-top: 8px;
    padding: 16px 80px;
  }
}
