.tourists-to-vietnam {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 30px;
    font-weight: 700;
    color: #006e99;
    margin-top: 10px;
    text-align: center;
  }
  .content {
    margin: 10px 0 30px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
  }

  .underline{
    text-decoration: underline;
  }

  .period-title{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .select-period-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    flex-wrap: wrap;
    gap: 12px;
  }

  .product-price{
    color: #F00;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .select-box-period{
    width: 200px;
  }

  @media (max-width: 1200px) {
    .select-box-period{
      width: 170px;
    }
  }
}

.carousel-content {
  word-wrap: break-word;
}

.tab-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #c6c6c6;
  cursor: pointer;
  .tab-item-text {
    padding: 7px 0;
    display: flex;
    justify-content: center;
    width: 150px;
  }
  .tab-divider {
    background: #dbdbdb;
    border-radius: 70px;
    width: 100%;
    height: 1.66px;
  }
  &.active {
    font-weight: 700;
    color: #006e99;
    .tab-divider {
      background: #006e99;
    }
  }
}

.tab-nav {
  display: flex;
  margin-bottom: 25px;
  width: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.tab-nav::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}
.tab-nav::-webkit-scrollbar-track {
  background: rgba(0,0, 0, 0);
}

/* Handle */
.tab-nav::-webkit-scrollbar-thumb {
  background: rgba(0,0, 0, 0.15);
  border-radius: 10px;
}

/* Handle on hover */
.tab-nav::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0, 0, 0.3);
}
