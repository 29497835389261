.customer-comments {
  padding-bottom: 120px;
  position: relative;

  .customer-comments-title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 60px;
  }

  .quotation-icon {
    position: absolute;
    right: -30px;
    top: -10px;
  }

  .slick-carousel {
    //margin-left: -8px;
    //margin-right: -8px;
    width: unset;
  }

  .slick-dots {
    display: none !important;
  }

  .carousel-item {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 20px;

  }

  .button-arrow {
    z-index: 2000;
    padding: 0
  }

  .customer-image {
    width: 94px;
    height: 94px;
    object-fit: cover;
    border-radius: 50%;
  }
  .customer-avatar{
    display: flex;
  }

  .content-section {
    background-color: #F1FAFC;
    margin: 0 !important;
    padding: 16px 18px 8px 14px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 0px #00000040;


  }
  .customer-name-role{
    margin-left: 30px;
    margin-top: 20px;
  }

  .customer-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  .customer-review {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 14px;
    min-height: 60px;
  }

  .customer-rating {

    margin-top: 18px;

    img {
      margin: auto;

      width: auto;
      height: 11px
    }
  }

  @media (max-width: 1200px) {
    .quotation-icon {
      width: 150px;
      height: auto;
      top: 20px;
      right: 30px;
    }
    .slick-carousel {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }


  @media (max-width: 992px) {
    margin-top: 60px;
    padding-bottom: 60px;

    .quotation-icon {
      width: 150px;
      height: auto;
      top: 40px;
      right: 0;
    }

    .slick-carousel {
      width: 100%;
    }


  }

  @media (max-width: 767px) {
    .quotation-icon {
      display: none;
    }
  }


  @media (max-width: 650px) {
    margin-top: 0;

    .customer-comments-title {
      font-size: 30px;
      margin-bottom: 40px;
    }


    .slick-carousel {
      margin: unset;
    }

    .content-section {
      padding: 16px 18px 8px 14px !important;
    }
    .button-arrow{
     img {
      display: none;
     }
    }
  }

}



