.general-information-block {
  background-image: url(/images/aboutUsPage/background-general-information.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100%;
  .panel {
    margin-top: 50px;
    .title {
      font-weight: 600;
      font-size: 30px;
    }
    .content {
      font-weight: 400;
      font-size: 16px;
      opacity: 0.8;
      padding-right: 400px;
      padding-bottom: 60px;
      // margin-top: 44px;
      text-align: justify;
    }
  }
}
.yt-block {
  margin-top: 125px;
  .title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .panel {
    display: flex;
    gap: 33px;
    padding-right: 104px;
    .video-section {
      flex: 60%;
      iframe {
        width: 100%;
      }
    }
    .content-section {
      flex: 40%;
    }
  }
}
.service-provided-block {
  margin-top: 82px;
  .title {
    font-weight: 600;
    font-size: 30px;
  }
  .description {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    padding-right: 104px;
  }
  .main-content {
    margin-top: 48px;
    // display: flex;
    // gap: 48px;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 48px;
    .content-item {
      img {
        width: 100% !important;
      }
      .content-item-decription {
        margin-top: 31px;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
      }
    }
  }
}
.contact-block {
  margin: 68px 0 84px;
  .title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 14px;
  }
  .panel {
    background-image: url(/images/aboutUsPage/background-contact.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    .content {
      padding: 73px 104px 180px 83px;
      border-radius: 10px;
      background: transparent;
      .main-content {
        background: #ffffff;
        border-radius: 10px;
        padding: 30px 30px 112px;
        .company-name {
          font-weight: 400;
          font-size: 17px;
        }
        .company-location {
          font-weight: 300;
          font-size: 13px;
        }
        .common-margin {
          margin-top: 27px;
        }
      }
    }
  }
}
@media (max-width: 1800px) {
}

@media (max-width: 1500px) {
}

@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
  .yt-block {
    .panel {
      padding-right: 60px;
    }
  }
  .general-information-block {
    .panel {
      .content {
        padding-right: 300px;
      }
    }
  }
}

@media (max-width: 990px) {
  .yt-block {
    .panel {
      padding-right: 0;
      flex-wrap: wrap;
    }
  }
  .service-provided-block {
    .description {
      padding-right: 0;
    }
  }
  .general-information-block {
    background: none;
    .panel {
      .content {
        padding-right: 0;
        padding-bottom: 0;
      }
    }
  }
  .contact-block {
    .panel {
      background: none;
      .content {
        padding: 0;
      }
    }
  }
}

@media (max-width: 650px) {
  .general-information-block {
    .panel {
      padding-top: 50px;
      margin-top: 0;
      .content {
        margin-bottom: 20px;
      }
    }
  }
  .yt-block {
    margin-top: 50px;
  }
  .service-provided-block {
    margin-top: 50px;
    .main-content {
      gap: 20px;
      .content-item {
        .content-item-decription {
          margin-top: 20px;
          font-size: 18px;
        }
      }
    }
  }
  .contact-block {
    margin: 50px 0;
    .panel {
      .content {
        .main-content {
          padding: 30px;
        }
      }
    }
  }
}
