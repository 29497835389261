.services-provided {
  margin-bottom: 120px;
  
  h4 {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }

  .services-provided-description {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 48px;
  }

  .services-list {
    display: flex;
    column-gap: 48px;
  }

  .service-item {
    flex: 1;
    //min-width: 300px;
    //max-width: 100%;
  }

  .service-item-icon {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .service-item-name {
    text-align: center;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 992px) {
    .services-list {
      column-gap: 24px;
    }
    .service-item-icon {
      margin-bottom: 16px;
    }
    .service-item-name {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    .services-list {
      row-gap: 30px;
      flex-direction: column;
    }

    .service-item {
      width: 100%;
      text-align: center;
    }


    .service-item-icon {
      width: 60%;
      height: auto;
    }
  }

  @media (max-width: 576px) {
    .service-item-icon {
      width: 85%;
    }
  }
}
