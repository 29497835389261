.find-us {

  margin-bottom: 120px;

  h4 {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }

  .find-us-content {
    display: flex;
    column-gap: 33px;
  }

  .find-us-video {
    img {
      border-radius: 5px;
    }
  }

  .find-us-description {
    color: #000;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }

  @media (max-width: 1200px) {
    .find-us-video {
      img {
        width: 500px;
        height: auto;
      }
    }
  }

  @media (max-width: 992px) {
    .find-us-content {
      flex-direction: column;
    }
    .find-us-video {
      img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 60px;
  }
}
