.call-us {
  display: flex;
  justify-content: center;
  margin-top: 60px;

  button {
    border: 2px solid #1992bc;
    border-radius: 50px;
    background: #ffffff;
    color: #3f3f3f;
    font-size: 20px;
    font-weight: 500;
    line-height: 55px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.sim-order {
  width: 811px;
  max-width: 100%;
  border: 2px solid #1992bc;
  border-radius: 24px;
  padding: 28px;
  margin-top: 40px;
  margin-bottom: 120px;
  margin-right: auto;
  margin-left: auto;

  .header-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-title {
      display: flex;
      align-items: center;
      justify-items: center;
      font-size: 30px;
      font-weight: 800;
      line-height: 50px;

      img {
        width: 29px;
        height: 29px;
      }

      span {
        margin-left: 8px;
      }
    }

    .header-content {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .input-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 20px;

    input,
    textarea {
      max-width: 484px;
      font-size: 18px;
      font-weight: 500;
      padding-right: 11px;
      padding-left: 11px;
    }

    .select-section {
      width: 100%;
      max-width: 484px;
      display: flex;
      gap: 24px;

      .select-box {
        width: 100%;
      }

      .amount-box {
        width: 75px;
        font-size: 18px;
        font-weight: 500;
      }

      .cancel-box {
        display: flex;
        align-items: center;

        img {
          width: 31px;
          height: 28px;
          cursor: pointer;
        }
      }
    }

    .item-section {
      .ant-space {
        gap: 24px !important;
        align-items: flex-start;
        font-size: 18px;
        font-weight: 500;

        .ant-space-item:nth-child(1) {
          width: 100%;
        }

        .ant-space-item:nth-child(2) {
          .ant-form-item {
            width: 100% !important;
          }

          width: 100px !important;
          font-size: 18px;
          font-weight: 500;
        }

        .ant-space-item:nth-child(3) {
          height: 40px;
          display: flex;
          align-items: center;

          img {
            width: 31px;
            height: 28px;
            cursor: pointer;
          }
        }
      }

      // .ant-space:first-child {
      //   .ant-space-item:nth-child(2) {
      //     width: 80px !important;
      //   }
      // }
    }
  }

  .submit-block {
    display: flex;
    justify-content: center;
    margin-top: 23px;

    button {
      border-radius: 56px;
      background: #1992bc;
      border: 2px solid #1992bc;
      color: #f4f4f4;
      font-size: 16px;
      font-weight: 700;
      padding-right: 33px;
      padding-left: 33px;
      line-height: 46px;
    }

    button:focus {
      outline: none;
    }
  }
}

.common-modal {
  width: 100%;
  padding: 10px 6px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  .title {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
  }

  .cancel-btn {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 5px;
    background: #1992bc;
    height: 26px;
    color: white;
    border: 1px solid #1992bc;
  }
}

.modal-register-success {
  .content {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
}

.modal-call-info {
  .content {
    font-size: 16px;
    font-weight: 600;
    color: #222222;
    text-align: center;
    margin-bottom: 15px;

    img {
      height: 20px;
      width: 20px;
      margin-right: 15px;
    }
  }
}

@media (max-width: 650px) {
  .cancel-icon {
    img {
      scale: 0.8
    }
  }
}