.contact {
  width: 100%;

  .contact-banner {
    background: linear-gradient(99.9deg, #0050F1 15.27%, #1ED54C 94.13%);
    padding: 70px 70px 46px 78px;
    position: relative;
    margin-bottom: 120px;
  }

  .service-invitation {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: capitalize;
    max-width: 645px;
  }


  .service-description {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 156%;
    letter-spacing: 0.9px;
    text-transform: capitalize;
    margin-bottom: 0;
    max-width: 345px;
    margin-top: 70px;
  }

  .contact-btn {
    background: #fff;
    cursor: pointer;
    position: absolute;
    right: 70px;
    bottom: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 5px;
    text-transform: capitalize;

    &:active {
      background: #F8F8FC;
    }
  }


  @media (max-width: 990px) {
    .contact-banner {
      padding-top: 50px;
    }
    .service-invitation {
      font-size: 35px;
    }
  }

  @media (max-width: 650px) {
    .container {
      padding: unset;
    }

    .contact-banner {

      padding: 20px;

      br {
        display: none;
      }
    }

    .service-invitation {
      font-size: 25px;
      line-height: unset;
      letter-spacing: unset;
    }

    .contact-btn {
      position: unset;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}
