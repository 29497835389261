.our-partners {
  text-align: center;
    margin-bottom: 120px;


  .our-partners-title {
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 600;
  }

  .line {
    height: 1px;
    background: #1992BC;
    width: 60%;
    margin: 0 auto;
  }

  .partner-list {
    display: flex;
    justify-content: center;
    column-gap: 32px;
    margin-top: 52px;

  }

  .partner-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 84px;
    background: #FFFFFF;
    flex-shrink: 0;
  }

  @media (max-width: 1200px) {
    .partner-list {
      flex-wrap: wrap;
      row-gap: 32px;
      margin-bottom: 60px;
    }
  }

  @media (max-width: 990px) {
    .line {
      width: 80%;
    }


  }

  @media (max-width: 650px) {

    .our-partners-title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .partner-list {
      margin-top: 20px;
      gap: 20px;
    }
  }
}
